/*********** My classes ***********/
.gradient {
    background: linear-gradient(to bottom, var(--theme-color), var(--theme-color-lighter));
}

/*********** OVERRIDES ***********/

h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
}

body {
    margin: 0;
    font-family: "Lato", Arial, sans-serif;
}

.site-page-header {
    padding: 16px 0 24px;
    align-items: 'center';
 }
 
.hide-table-sort .ant-table-column-sorter {
    display: none;
 }
 
.ant-affix .affix-submit-save {
    padding: 16px;
    background: white; 
    
 }

 .ant-affix  .affix--shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.ant-layout-sider-trigger {
    background: var(--theme-color);
}

.ant-table-summary {
    background: #fafafa;
}

.ant-statistic-content-suffix {
    font-size: small;
}

.ant-badge {
    display: block !important;
}

.ant-page-header-heading {
    align-items: center;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}

.editable-cell {
    border-radius: 2px;
    padding: 5px 0 5px 12px;
    min-height: 32px
}

.editable-cell:hover {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    padding: 4px 0 4px 11px
}

.ant-form-item-label {
    padding: 0 0 2px !important;
}

.select-team-row:hover {
    cursor: pointer;
}